import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import labelImg from './assets/good.svg';

export const Container = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 135%;
  color: #84919c;
  white-space: pre-line;
`;

export const AvatarContainer = styled.div(
  ({ $showLabel }) => css`
    position: relative;
    display: inline-flex;

    ${$showLabel &&
    css`
      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        content: url(${labelImg});
        width: 16px;
        height: 16px;
        line-height: 1;
      }
    `}
  `
);

export const AvatarImg = styled(Image)`
  height: 44px;
  width: auto;
  border-radius: 50%;

  &::before {
    content: url(${labelImg});
  }
`;
