import styled, { css } from 'styled-components';
import { ThemesEnum } from '@uc-common/theme';

export const Section = styled.section(
  ({ theme: { tablet } }) => css`
    padding-top: 100px;
    overflow: hidden;
    padding-bottom: 80px;

    ${tablet()} {
      padding-top: 140px;
    }
  `
);

export const DarkSection = styled(Section).attrs({
  'data-navbar-theme': ThemesEnum.DARK,
})(
  () => css`
    background-color: #000000;
  `
);
