import React from 'react';
import styled, { css } from 'styled-components';
import { Avatar } from '../../Avatar';

export const Container = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    justify-content: center;
    margin-top: 120px;

    ${tablet()} {
      margin-top: 140px;
    }

    ${laptop()} {
      grid-gap: 24px;
      margin-top: 170px;
    }
  `
);

export const Logo = styled.img`
  width: 150px;
  justify-self: center;
`;

export const Quote = styled.blockquote(
  ({ theme: { tablet, laptop } }) => css`
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;

    ${tablet()} {
      font-size: 30px;
    }

    ${laptop()} {
      font-size: 40px;
    }
  `
);

export const StyledAvatar = styled(Avatar)`
  justify-self: center;
`;

export const Review = () => (
  <Container>
    <Logo
      src="https://ucarecdn.com/106db318-bd00-45fc-9ff6-34b932b85395/stacksharebw.svg"
      alt="stackshare"
    />
    <Quote>“Your product is great.”</Quote>
    <StyledAvatar
      src="https://ucarecdn.com/44e8ea86-b194-4b41-8ee2-6418a7fb8c34/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      alt="Yonas Beshawred | Founder and CEO"
      showLabel
    />
  </Container>
);
