import React from 'react';
import { AvatarContainer, AvatarImg, Container, Text } from './Avatar.styles';

export const Avatar = ({ src, alt, className, showLabel }) => (
  <Container className={className}>
    <AvatarContainer $showLabel={showLabel}>
      <AvatarImg src={src} alt={alt} />
    </AvatarContainer>
    <Text>{alt.split('|').join('\n')}</Text>
  </Container>
);
