import React from 'react';
import { useStaticQuery } from 'gatsby';

import { ButtonTypeEnum } from '@uc-common/button';
import { formatNumber } from '@uc-common/utils';
import { plansQuery } from '~/src/pages/pricing';
import { prettyBytes } from '~/src/utils/formatting';

import { Feature } from '../Feature';
import { Button, Hint, Limits, Price, StyledPlanCard } from './PlanCardFree.styles';
import { PlanCardContent, CardFeatures, PlanCardRightHalf } from '../PlanCard';

const features = [
  'Best-in-class file uploader',
  'Integrated cloud storage & API',
  'Image optimization to next-gen formats',
  'Content-aware image crop and resize',
  'Delivery through a worldwide CDN',
];

const FALLBACK_FREE_PLAN = {
  features: {
    storage: {
      hard_limit: 1073741824,
    },
    traffic: {
      hard_limit: 16106127360,
    },
    uploads: {
      hard_limit: 5000,
    },
  },
};

export const PlanCardFree = ({ btnSignUpProps, ...props }) => {
  const {
    plans: { availablePlans: plans },
  } = useStaticQuery(plansQuery) ?? [];
  const freePlan = plans.find(({ price }) => Number(price) === 0) ?? FALLBACK_FREE_PLAN;
  const hasOperations = freePlan.features.operations?.is_enabled;

  return (
    <StyledPlanCard {...props}>
      <PlanCardContent>
        <CardFeatures>
          {features.map((feature) => (
            <Feature key={feature}>{feature}</Feature>
          ))}
        </CardFeatures>
        <PlanCardRightHalf>
          <Price>Start for free</Price>
          <Limits>
            {hasOperations
              ? `${formatNumber(freePlan.features.operations.hard_limit)} operations`
              : `${formatNumber(freePlan.features.uploads.hard_limit)} uploads`}{' '}
            / mo, {prettyBytes(freePlan.features.traffic.hard_limit)}
            <br />
            traffic / mo, {prettyBytes(freePlan.features.storage.hard_limit)} storage
          </Limits>
          <Button
            forwardedAs="a"
            href="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            long
            {...btnSignUpProps}
          >
            Get started
          </Button>
          <Hint>No credit card required.</Hint>
        </PlanCardRightHalf>
      </PlanCardContent>
    </StyledPlanCard>
  );
};
