import styled, { css } from 'styled-components';

export const PlanCard = styled.div(
  ({ theme: { isDark } }) => css`
    --border-radius: 12px;

    position: relative;
    transform: translate3d(0, 0, 0);
    border-radius: var(--border-radius);
    color: ${isDark ? '#fff' : '#1f2427'};

    ${isDark
      ? css`
          --icon-bg: rgba(0, 255, 163, 0.19);
          --icon-color: #5fff65;

          border: 1px solid #000;
        `
      : css`
          box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.16),
            0 4px 18px rgba(0, 0, 0, 0.08);

          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -15px;
            background: linear-gradient(
              90deg,
              #18bafe 0%,
              #daa5ff 19.65%,
              #ff7eb1 82.67%,
              #ffc4b8 100%
            );
            opacity: 0.5;
            filter: blur(60px);
            border-radius: 123px;
            width: calc(100% - 20px);
            height: 67px;
          }
        `}
  `
);

export const PlanCardContent = styled.div(
  ({ theme: { tablet, isDark } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 1;
    border-radius: var(--border-radius);

    ${tablet()} {
      flex-direction: row;
    }

    ${isDark
      ? css`
          background: #111113;
        `
      : css`
          background: #fff;
        `}
  `
);

export const CardFeatures = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding: 20px;

    ${tablet()} {
      flex-basis: 50%;
      padding: 40px 35px;
      justify-content: center;
    }

    ${laptop()} {
      padding: 60px;
    }
  `
);

export const PlanCardRightHalf = styled.div(
  ({ theme: { tablet, laptop, isDark } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    padding: 40px 35px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 5%;
      margin: auto;
      width: 90%;
      height: 1px;
      background: ${isDark ? '#2b2b39' : '#e7e7e7'};
    }

    ${tablet()} {
      padding: 40px;
      flex-basis: 50%;

      &::before {
        top: 9%;
        left: 0;
        height: 82%;
        width: 1px;
      }
    }

    ${laptop()} {
      padding: 58px 0;
    }
  `
);
