import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';

const CONTENT_MAX_WIDTH = '840px';

export const SubTitle = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    margin: 30px auto 20px;
    max-width: ${CONTENT_MAX_WIDTH};

    ${tablet()} {
      font-size: 38px;
      line-height: 1.3;
    }

    ${laptop()} {
      font-size: 42px;
    }
  `
);

export const SubText = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #bbc4d2;
    margin-left: auto;
    margin-right: auto;
    max-width: ${CONTENT_MAX_WIDTH};

    em {
      font-style: normal;
    }

    ${tablet()} {
      font-size: 18px;
    }

    ${laptop()} {
      font-size: 20px;
    }

    ${desktop()} {
      font-size: 22px;
      max-width: 860px;
    }
  `
);

export const CTAButton = styled(Button)(
  ({ theme: { tablet } }) => css`
    display: flex;
    margin: 30px auto 0;
    min-width: 198px;
    width: max-content;
    height: 48px;
    font-weight: 700;

    ${tablet()} {
      margin-top: 40px;
    }
  `
);
