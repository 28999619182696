import styled, { css } from 'styled-components';
import { Button as BitButton } from '@uc-common/button';
import { PlanCard } from '../PlanCard';
import { LinkWithArrow } from '../Typography';

export const StyledPlanCard = styled(PlanCard)(
  ({ theme: { tablet } }) => css`
    margin-top: 45px;

    ${tablet()} {
      margin-top: 70px;
    }
  `
);

export const Price = styled.span(
  ({ theme: { laptop } }) => css`
    font-weight: 700;
    letter-spacing: 0.01em;
    line-height: 1.2;
    font-size: 32px;

    ${laptop()} {
      font-size: 42px;
    }
  `
);

export const Limits = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 10px;
    font-size: 14px;
    max-width: 200px;
    line-height: 1.2;
    text-align: center;

    ${tablet()} {
      font-size: 16px;
      margin-top: 12px;
    }

    ${laptop()} {
      margin-top: 20px;
      font-size: 20px;
      max-width: 286px;
    }
  `
);

export const Hint = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 14px;
    line-height: 1.2;
    margin-top: 16px;
    color: #8c98a3;
    transition: color 0.3s;
    text-decoration: none;

    ${LinkWithArrow} {
      font-size: 14px;
    }

    ${tablet()} {
      margin-top: 22px;
    }

    ${laptop()} {
      font-size: 16px;
      margin-top: 30px;

      ${LinkWithArrow} {
        font-size: 16px;
      }
    }
  `
);

export const Button = styled(BitButton)(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 20px;

    ${tablet()} {
      margin-top: 22px;
    }

    ${laptop()} {
      margin-top: 29px;
    }
  `
);
