import styled, { css } from 'styled-components';

export const FeatureContainer = styled.span(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    ${tablet()} {
      font-size: 17px;
      margin-top: 13px;
    }

    ${laptop()} {
      font-size: 20px;
      margin-top: 20px;
    }
  `
);

export const FeatureIcon = styled.svg(
  ({ theme: { tablet } }) => css`
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: var(--icon-color, #3771ff);

    ${tablet()} {
      width: 24px;
      height: 24px;
      margin-right: 18px;
    }
  `
);
