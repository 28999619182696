import React from 'react';

import { FeatureContainer, FeatureIcon } from './styles/Feature.styles';

export const Feature = ({ className, children }) => (
  <FeatureContainer className={className}>
    <FeatureIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="none"
    >
      <use xlinkHref="#circle-checkmark" />
    </FeatureIcon>
    {children}
  </FeatureContainer>
);
